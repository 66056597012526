import React, { useEffect } from 'react';
import { Col, Row, Spinner, Button } from 'reactstrap';
import FactCard from './FactCard';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import _ from 'lodash';

const ResultsBody = (props) => {
  const searchText = new URLSearchParams(window.location.search).get('q');
  let [random, setRandom] = React.useState(1);
  let [loaded, setLoaded] = React.useState(false);
  let [offset, setOffset] = React.useState(0);
  let [data, setData] = React.useState([]);
  let [hasMore, setHasMore] = React.useState(true);

  let history = useHistory();
  function goHome() {
    history.replace(`/`);
  }

  function loadMore() {
    props.setNewSearch(false);
    setOffset(offset + 20);
    setRandom(Math.floor(Math.random() * (100 - 1) + 1));
  }

  function loadData() {
    setLoaded(false);
    const searchTextTemp = new URLSearchParams(window.location.search).get('q');
    const local = window.location.hostname === 'localhost';
    let offsetTemp = offset;
    if (props.newSearch) {
      offsetTemp = 0;
      setOffset(0);
      setData([]);
    }
    const searchHost = local ? 'http://localhost' : 'https://sdrf4rt87yuhjnal45hbgvb.herokuapp.com';
    fetch(`${searchHost}/search?hl=en&num_results=20&force=false&offset=${offsetTemp}&query=${searchTextTemp}`, {
      headers: {
        Referer: searchHost,
      },
    })
      .then((response) => response.text())
      .then((response) => {
        const l0 = response.replace(`)]}'`, '');
        const l1 = JSON.parse(l0);
        const tempData = _.map(l1[0][1], (d, id) => {
          let ratingColor = 'warning';
          if (_.includes(_.toLower(d[0][3][0][3]), 'false')) ratingColor = 'danger';
          else if (_.includes(_.toLower(d[0][3][0][3]), 'true')) ratingColor = 'success';

          const linkUrl = d[0][3][0][1];
          const linkUrlObj = linkUrl ? new URL(d[0][3][0][1]) : { hostname: '' };
          const claimText =
            d[0][0] && (d[0][0].charAt(0) === `"` || d[0][0].charAt(0) === `“`) ? d[0][0].slice(1, -1) : d[0][0];
          const key = new Date().getTime() +'-'+ Math.floor(Math.random() * (150000 - 100000) + 100000);
          const x = {
            id,
            claimBy: d[0][1][0],
            claimText,
            image: d[1],
            ratingBy: d[0][3][0][0][0],
            rating: d[0][3][0][3],
            ratingColor,
            linkUrl,
            linkText: d[0][3][0][8],
            linkDomain: linkUrlObj.hostname,
            shareLink: `https://retrue.org/results?q=${claimText}`,
            key,
          };
          return x;
        });
        setHasMore(tempData.length === 20);
        offsetTemp === 0 ? setData(tempData) : setData([...data, ...tempData]);
        setLoaded(true);
      })
      .catch((e) => {
        setData([]);
        setLoaded(true);
      });
  }

  useEffect(() => {
    loadData();
  }, [props.searchTextChange, random]);

  return (
    <div className="section">
      <Row>
        {loaded && _.size(data) <= 0 ? (
          <Col xs="12" className="text-center">
            <div className="empty-space-3"></div>
            <h6 className="text-muted fw-500 fs-smaller">Fact check search results for: {searchText}</h6>
            <br />
            &nbsp;&nbsp;&nbsp; No Results Found ...
            <br />
            <br />
            <Button className="primary" outline onClick={goHome}>
              Search
            </Button>
            <div className="empty-space-3"></div>
          </Col>
        ) : (
          ''
        )}
        <Col xs="12" md={{ size: 10, offset: 1 }}>
          {loaded && _.size(data) > 0 ? (
            <h6 className="text-muted fw-500 fs-smaller">Fact check search results for: {searchText}</h6>
          ) : (
            ''
          )}
          <InfiniteScroll
            style={{ overflowX: 'hidden' }}
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            endMessage={
              data.length > 0 ? (
                <p style={{ textAlign: 'center' }}>
                  <b>No more results ...</b>
                </p>
              ) : (
                ''
              )
            }
          >
            {data.map((d) => (
              <FactCard
                key={d.key}
                claimBy={d.claimBy}
                claimText={d.claimText}
                image={d.image}
                ratingBy={d.ratingBy}
                rating={d.rating}
                linkUrl={d.linkUrl}
                linkText={d.linkText}
                ratingColor={d.ratingColor}
                linkDomain={d.linkDomain}
                shareLink={d.shareLink}
              />
            ))}
          </InfiniteScroll>
        </Col>
      </Row>
      {!loaded ? (
        <Col xs="12" className="text-center">
          <div className="empty-space-3"></div>
          <Spinner animation="border" variant="primary" size="xl" />
          <br />
          &nbsp;&nbsp;&nbsp;Loading ...
          <div className="empty-space-3"></div>
        </Col>
      ) : (
        ''
      )}
      <div className="empty-space-3"></div>
    </div>
  );
};
export default ResultsBody;
