// Layouts
import DefaultLayout from "./layouts/DefaultLayout";

// Views
import About from "./views/About";
import Results from "./views/Results";
import Search from "./views/Search";
import NotFound from "./views/NotFound";

let presentation_routes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Search,
    name: "Search"
  },
  {
    path: "/results",
    layout: DefaultLayout,
    component: Results,
    name: "Results"
  },
  {
    path: "/about",
    layout: DefaultLayout,
    component: About,
    name: "About"
  },
  {
    path: "/*",
    layout: DefaultLayout,
    component: NotFound,
    name: "NotFound"
  },
];

let routes = [...presentation_routes];
export { routes, presentation_routes };
