import React from 'react';
import { routes } from "./routes";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './fontawesome';
import TagManager from 'react-gtm-module'
import { ToastContainer } from 'react-toastify';

const tagManagerArgs = {
  gtmId: 'GTM-W85D39S'
}

// GTM Trigger only in retrue.org domain pages
TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              status={route.status}
              component={ () => {
                return (
                  <route.layout>
                    <route.component />
                  </route.layout>
                )
              }}
            />
          );
        })}
      </Switch>
    </Router>
  );
};

export default App;
