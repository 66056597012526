import React from 'react';
import MainNavbar from '../components/presentation/MainNavbar';
import SearchHeader from '../components/presentation/SearchHeader';

class Search extends React.Component {
  render() {
    document.title = `ReTrue.Org`;
    return (
      <div className="index">
        <MainNavbar />
        <SearchHeader />
      </div>
    );
  }
}

export default Search;
