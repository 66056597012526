import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const AboutBody = (props) => {
  let history = useHistory();
  function goHome() {
    history.replace(`/`);
  }
  return (
    <div className="page-hero bg-primary text-white" id="banner">
      <div className="bubbles d-none d-md-block">
        <div className="bubble bubble-1 rotate-bubble bg-warning"></div>
        <div className="bubble bubble-2 bubble-bottom-right bg-danger rotate-bubble"></div>
        <div className="bubble bubble-3 bubble-top-right bg-warning rotate-bubble"></div>
        <div className="bubble bubble-4 bubble-top-left bg-info rotate-bubble"></div>
        <div className="bubble bubble-5 bg-info rotate-bubble"></div>
        <div className="bubble bubble-6 bubble-bottom-right bg-danger rotate-bubble"></div>
        <div className="bubble bubble-7 bubble-top-right bg-success rotate-bubble"></div>
        <div className="bubble bubble-8 bubble-top-left bg-success rotate-bubble"></div>
        <div className="bubble bubble-9 bg-warning rotate-bubble"></div>
        <div className="bubble bubble-10 bg-danger rotate-bubble"></div>
        <div className="bubble bubble-11 bubble-bottom-right bg-info rotate-bubble"></div>
        <div className="bubble bubble-12 bubble-top-right bg-success rotate-bubble"></div>
        <div className="bubble bubble-13 bubble-top-left bg-success rotate-bubble"></div>

        <div className="circle circle-1 bg-light rotate-circle"></div>
        <div className="circle circle-2 bg-success rotate-circle"></div>
        <div className="circle circle-3 bg-danger rotate-circle"></div>
        <div className="circle circle-4 bg-info rotate-circle"></div>
        <div className="circle circle-5 bg-success rotate-circle"></div>
        <div className="circle circle-6 bg-info rotate-circle"></div>
        <div className="circle circle-7 bg-warning rotate-circle"></div>
        <div className="circle circle-8 bg-white rotate-circle"></div>
        <div className="circle circle-9 bg-warning rotate-circle"></div>
        <div className="circle circle-10 bg-danger rotate-circle"></div>
      </div>
      <Container>
        <Row>
          <Col xs="12" lg={{ size: 8, offset: 2 }} className="text-center">
            <div className="title">
              <h1 className="display-4 text-white">404 | Page Not Found</h1>
              <br/><br/>
              <h4 className="text-white">ReTrue.Org</h4>
              <h5 className="text-white">Open Fact Check - Global Fact Check Explorer.</h5>
              <br/><br/>
              <Button className="btn btn-light" onClick={goHome}>
                Go to home
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutBody;
