import React, { useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from './SearchInput';

const SearchHeader = (props) => {
  let history = useHistory();
  let [searchText, setSearchText] = React.useState();

  const handleClick = () => {
    if (!searchText) return false;
    history.push(`/results?q=${searchText}`);
  };

  useEffect(() => {}, []);

  return (
    <div className="page-hero bg-primary text-white" id="banner">
      <div className="bubbles d-none d-md-block">
        <div className="bubble bubble-1 rotate-bubble bg-warning"></div>
        <div className="bubble bubble-3 bubble-top-right bg-warning rotate-bubble"></div>
        <div className="bubble bubble-4 bubble-top-left bg-info rotate-bubble"></div>
        <div className="bubble bubble-5 bg-info rotate-bubble"></div>

        <div className="circle circle-1 bg-light rotate-circle"></div>
        <div className="circle circle-2 bg-success rotate-circle"></div>
        <div className="circle circle-3 bg-danger rotate-circle"></div>
        <div className="circle circle-4 bg-info rotate-circle"></div>
        <div className="circle circle-5 bg-success rotate-circle"></div>
        <div className="circle circle-6 bg-info rotate-circle"></div>
        <div className="circle circle-7 bg-warning rotate-circle"></div>
        <div className="circle circle-8 bg-white rotate-circle"></div>
        <div className="circle circle-9 bg-warning rotate-circle"></div>
        <div className="circle circle-10 bg-danger rotate-circle"></div>
      </div>
      <Container>
        <Row>
          <Col xs="12" lg={{ size: 8, offset: 2 }}>
            <div className="title">
              <h4 className="text-white">
                ReTrue.Org - <small>Open Fact Check</small>
              </h4>
              <div className="form-inline mb-3">
                <FormGroup className="flex-grow-1">
                  <Label className="sr-only" for="email4">
                    Search
                  </Label>
                  {/* <Input
                    style={{display: 'none'}}
                    onKeyDown={handleSearchEnter}
                    innerRef={searchInput}
                    type="text"
                    className="flex-grow-1 mr-2 input-dark"
                    id="search-text"
                    placeholder="Fact check here for a topic or a person ..."
                  /> */}
                  <SearchInput handleClick={handleClick} setSearchText={setSearchText} />
                </FormGroup>
                <Button
                  onClick={handleClick}
                  type="submit"
                  id="search-btn"
                  color="primary"
                  size="lg"
                  className="mt-0 mt-md-3 mt-lg-0"
                >
                  {' '}
                  <FontAwesomeIcon className="mr-2" icon="search" />
                  Search
                </Button>
              </div>

              <h6 className="text-success">
                <FontAwesomeIcon icon="check" /> Global Fact Check Explorer
              </h6>
            </div>
            <div className="empty-space"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SearchHeader;
